@font-face {
  font-family: ooredoobold;
  src: url(/fonts/ooredoo/ooredoo-Bold-webfont.eot);
  src: url(/fonts/ooredoo/ooredoo-Bold-webfont.eot?#iefix) format('embedded-opentype'), url(/fonts/ooredoo/ooredoo-Bold-webfont.woff2) format('woff2'), url(/fonts/ooredoo/ooredoo-Bold-webfont.woff) format('woff'), url(/fonts/ooredoo/ooredoo-Bold-webfont.ttf) format('truetype'), url(/fonts/ooredoo/ooredoo-Bold-webfont.svg#ooredoobold) format('svg')
}

@font-face {
  font-family: ooredooregular;
  src: url(/fonts/ooredoo/ooredoo-Regular-webfont.eot);
  src: url(/fonts/ooredoo/ooredoo-Regular-webfont.eot?#iefix) format('embedded-opentype'), url(/fonts/ooredoo/ooredoo-Regular-webfont.woff2) format('woff2'), url(/fonts/ooredoo/ooredoo-Regular-webfont.woff) format('woff'), url(/fonts/ooredoo/ooredoo-Regular-webfont.ttf) format('truetype'), url(/fonts/ooredoo/ooredoo-Regular-webfont.svg#ooredooregular) format('svg')
}

@font-face {
  font-family: ooredoolight;
  src: url(/fonts/ooredoo/ooredoo-Light.eot);
  src: url(/fonts/ooredoo/ooredoo-Light.eot?#iefix) format('embedded-opentype'), url(/fonts/ooredoo/ooredoo-Light.woff2) format('woff2'), url(/fonts/ooredoo/ooredoo-Light.woff) format('woff'), url(/fonts/ooredoo/ooredoo-Light.ttf) format('truetype'), url(/fonts/ooredoo/ooredoo-Light.svg#ooredooregular) format('svg')
}

body {
  font-family: "ooredooregular" !important;
  background: #f5f5f500 !important;
  font-size: 14px !important;

}


.MuiFormLabel-root {
  font-family: "ooredooregular" !important;
  background: #f5f5f500 !important;
  font-size: 14px !important;

}

.app-header {
  background-color: #d2d2d2 !important;
  background-image: linear-gradient(to right, #EEEEEE, #b5b5b5, #1702028a);
  color: #111111 !important;
}
.breadcrumb{
  background-image: linear-gradient(#0041d22e, #4d425900);
  border-bottom: 0px !important;
  margin-bottom: 0rem!important;
}
.div_dash{
  height: 100%!important;
}
.container-fluid {
  height: 100%!important;
  padding: 5px 5px!important;
  /*height:calc(100vh - 200px)!important;*/
  /*overflow: hidden;*/
  /*overflow-x: hidden; !* Hide horizontal scrollbar *!*/
  /*overflow-y: scroll;*/
}
.content_div {
  height: 100%;
  margin: 0px;
  padding: 0px;
}
.Grid-item{
  padding-top: 6px !important;
  padding-bottom: 2px !important;
  margin-top: 1px !important;
  margin-bottom: 1px !important;
  border-radius: 50px !important;
  background-color: rgba(240, 240, 240, 0);
}
.Grid-item-date{
  border: 1px solid #f539001f !important;
}


.MuiTableSortLabel-root {
  font-family: "ooredoobold";
  font-size: 16px;
}

.MuiTableCell-head {
  line-height: 0.5rem !important;
}

.MuiButton-root {
  background-color: #ED1C24 !important;
  font-size: 18px;
  padding: 6px 12px 6px;
  color: white;
  position: relative;
  border: 1px solid transparent;
  text-decoration: none;
  overflow: hidden;
  -webkit-border-radius: 25px;
  -moz-border-radius: 25px;
  -ms-border-radius: 25px;
  -o-border-radius: 25px;
  border-radius: 25px;
  -webkit-transition: all 0.2s;
  -moz-transition: all 0.2s;
  transition: all 0.2s;
}
.MuiButton-root-default{
  background-color: rgba(237, 28, 36, 0) !important;
}


.MuiButtonBase-root {

}

.MuiButton-label {
  color: #fff;
}

.MuiToolbar-root {
  background-color: #D7D7D7;
}

.MuiTableRow-head {
  background-color: #E4E5E5;
}

.MuiTypography-root {
  font-weight: bold;
}

input.MuiInputBase-input {
  color: #000000a3;
}

label.MuiFormLabel-root {
  font-weight: bold;
  padding-inline-start: 20px;
}

label.MuiFormLabel-root.Mui-disabled {
  color: black;
}

* {
  font-family: ooredooregular;
}

.input-group > .form-control:not(:first-child), .input-group > .custom-select:not(:first-child) {
  border-radius: 50px;
  font-family: ooredooregular;
}

.input-group > .input-group-prepend > .btn, .input-group > .input-group-prepend > .input-group-text, .input-group > .input-group-append:not(:last-child) > .btn, .input-group > .input-group-append:not(:last-child) > .input-group-text, .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), .input-group > .input-group-append:last-child > .input-group-text:not(:last-child) {
  border-radius: 50px 0 0 50px;

}

.card.bg-primary {
  border-color: #f0f3f500;
}


.btn-primary {
  color: #fff;
  background-color: #ed1c24 !important;
  border-color: #ed1c24 !important;
  border-radius: 50px !important;
}

.MuiButton-text {
  padding: 4px 20px !important;
  border-radius: 50px !important;
}

.MuiOutlinedInput-notchedOutline {
  padding: 4px 20px !important;
  border-radius: 50px !important;
}

.custom-card {
  background-color: #d0d0d0 !important;
  background-image: url("../css/img/primaback.png");
  background-size: cover;
}

.MuiToolbar-regular {
  min-height: 0px !important;
}

.MuiIconButton-root {
  padding: 2px !important;
  font-size: 1.6rem !important;
}

.MuiTable-root .MuiInput-formControl {
  margin-top: 0px;
  padding-inline-start: 20px;
}

.MuiInputLabel-formControlCustom {
  margin-top: 0px;
  padding-inline-start: 5px;
}

/*padding-inline-start: 20px;
padding-top: 3px;*/

.MuiInput-underline {
  padding-inline-start: 0px;
  border: solid 1px #797A7A;
  border-radius: 30px;
}
.MuiInput-underline-red {
  padding-inline-start: 0px;
  border: solid 0px #797A7A;
  border-radius: 0px;
}
.MuiDivider-root {
  background-color: rgba(19, 6, 7, 0.31) !important;
  height: 1px!important;
}
.MuiInput-underline:before {
  content: none !important;
}

.MuiInput-underline:after {
  border-bottom: none !important;
}

.MuiSvgIcon-root {
  width: 0.8em;
  font-size: 0.9em !important;
}
.IconLarge{
  font-size: 2.3em!important;
  color: #dd4b39!important;
}
.IconLargeIndice{
  font-size: 3em!important;
}
.MuiSvgIcon-colorAction {
}
.MuiTable-root .MuiInput-formControl {
  padding-inline-start: 5px !important;
}


.MuiInputBase-input {
  height: 30px;
}

.ui-menu-item .ui-menu-item-wrapper.ui-state-active {
  margin: 0;
  border: none;
  outline: 1px solid #ccc;
  background-color: #e4ebf1;
  color: #000;
}

.MuiTypography-h5 {
  font-size: 0.8rem !important;
  font-family: ooredooregular !important;
  font-weight: 400;
  line-height: 1.334;
  letter-spacing: 0em;
}

.serch_text_field {

}

.label.MuiFormLabel-root
  /*.MuiSelect-select:focus{
    background-color: #FFFFFF;
  }*/
.MuiSelect-select {
  border-radius: 30px !important;
}

.sidebar .nav-link.active .nav-icon {
  color: #111111 !important;
}

.sidebar .nav-link:hover {
  background-image: linear-gradient(gray, #EEEEEE);
  background: #666666 !important;
}

.sidebar .nav-link.active {
  background-image: linear-gradient(gray, #EEEEEE);
  background: #958f8f !important;
}
.app-header .navbar-nav .dropdown-menu-right {
  background-image: linear-gradient(#333333,#4d425973);
  border-radius: 25px!important;
}
.dropdown-item{
  background-color: #8f8f8f;
  border-radius: 50px!important;
  position: relative;
  padding: 15px 24px;
  border-bottom: 0px solid #c8ced3!important;
}
.dropdown-header {
  padding: 10px 10px;
  /* background: #e4e7ea; */
  background-color: #e4e7ea00!important;
  color: #FFFFFF!important;
  border-bottom: 1px solid #a5adb4;
}

.MuiCircularProgress-colorPrimary {
  color: #f31c1c !important;
}

.MuiSelect-icon {
  top: calc(50% - 8px) !important;
}

.sidebar-minimized.sidebar-fixed .sidebar {
  width: 50px !important;
}

/*.image {
  backgroundImage: 'url(https://img.bfmtv.com/737/f540f7d22a402bc115b60f4099098.jpg)';
  backgroundRepeat: 'no-repeat';
  backgroundSize: 'cover';
  backgroundPosition: 'center';
}*/

.GridImage {
  display: block;
  width: 100%;
  height: 100%;
  background-position: 50% 50%;
}

/*#e4e5e6
MuiSelect-select
*/
.ButtonItemGreen {
  font-size: 0.60rem !important;
  color: #111111 !important;
  padding: 7px 9px !important;
  width: 100% !important;
  height: 100% !important;
  border-radius: 50px !important;
  border: 1px #111111;
}

.ButtonItemDefault {
  font-size: 0.60rem !important;
  color: #111111 !important;
  padding: 1px 4px !important;
  width: 100% !important;
  border-radius: 50px !important;
  border: 2px !important;
  text-align: center !important;
  font-size: x-small !important;
}

.MuiList-padding {
  padding-top: 1px !important;
  padding-bottom: 1px !important;
}

.GridItem {
  height: 15% !important;
  border: 1px !important;
  border-bottom: 1px !important;
  border-color: #FAFAFA;
}

.ListItem {
  padding: 2px !important;

}

.inputSearch {
  padding-inline-start: 20px;
  height: 100% !important;
  border-radius: 50px !important;
  background-color: #d4d4d400 !important;
}

.ButtonIcon {
  display: inline !important;
  border: solid 1px #ffffff !important;
  border-radius: 50px !important;
  color: #111111;
}

.div_list_item {
  border-radius: 10px !important;
  height: calc(100vh - 13.2em);
  overflow: hidden;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  overflow-y: scroll;
  background-color: #c62f2f21 !important;
}

.div_list_category {
  border-radius: 10px !important;
  margin-top: 10px !important;
  overflow: hidden;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  background-color: #c62f2f21 !important;
}

.MuiTypography-body1 {
  font-size: 0.7rem !important;
  font-family: ooredooregular !important;
  font-weight: 400;
  letter-spacing: 0.00938em;
}

.autocomplete_small {
  height: 1.8em !important;
  padding: 2px !important;
  margin: 7px !important;
  font-size: medium !important;
}

.main {
  overflow-y: hidden;
}

.MuiTableCell-root {
  /* display: table-cell; */
  padding: 0.5rem !important;
  text-align: left;
  font-family: ooredooregular !important;
  font-weight: 400;
  line-height: 1.43 !important;
  border-bottom: 1px solid #23282c30 !important;
  letter-spacing: 0.01071em;
  vertical-align: inherit;
}

.sidebar .nav-dropdown.open {
  background: #797A7A !important;
}

.sidebar {
  background: #797A7A !important;
}

.sidebar .nav-link .nav-icon {
  display: inline-block;
  width: 1.09375rem;
  margin: 0 0.5rem 0 0;
  font-size: 0.875rem;
  text-align: center;

}
.sidebar .nav-link{

}
.nav-icon{
  color: #b5b5b5!important;
}

.sidebar .sidebar-minimizer {
  background-color: #797A7A !important;
  }

.MuiToolbar-root {
  background-color: #ffffffb3 !important;
}
.MuiDialogTitle-root {
  background-color: #b3b3bf!important;
  padding: 16px 24px!important;
}
input, button, select, optgroup, textarea, p {
  font-family: ooredooregular !important;
  font-size: 14px !important;
}
.MuiDialog-paperWidthLg {
  border-radius: 20px!important;
}

input.MuiInputBase-input.MuiOutlinedInput-input.Mui-disabled.Mui-disabled.MuiInputBase-inputMarginDense.MuiOutlinedInput-inputMarginDense {
  border-radius: 50px !important;
  background-color: #5c6eca14 !important;
}

.MuiOutlinedInput-root {
  border-radius: 50px !important;
}

.MuiDialog-paperScrollPaper {
  max-height: 98% !important;
}

.MuiFormControl-root.MuiTextField-root {
  width: 100% !important;
}

.nav-dropdown .nav-dropdown-toggle {
}

.image {
  /*height: 350px;*/
  cursor: zoom-in;
}
.image-dial{
  height: 500px;
  width: 70rem;
  text-align: center;
}

.popup {
  height: 350px;
  width: 500px;
}

.slide-container {
  width: 70%;
  margin: auto;
}

.img{
  max-width: 100%;
  max-height:  100%;
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
}
.img:hover {
  box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.5);
}

/*.zoom {
  transition: transform .2s; !* Animation *!
  height: 500px;
  width: 700px;
}*/

.imageZoomed {
  cursor: zoom-out;
}

.dropdown-item.active, .dropdown-item:active {
  background-color: #d82020 !important;
}

button:focus {
  outline: 0px dotted !important;
  outline: 0px auto -webkit-focus-ring-color !important;
}
.canvasjs-chart-credit{
  display:none!important;
}
.canvasjs-chart-canvas {
  width: 100% !important;
}

.App {
  font-family: sans-serif;
  text-align: center;
}


/*------------------------------------------------------*/

.drag-handle {
  cursor: move !important;
}

.dragging-container tbody {
  xposition: relative;
  xoverflow: hidden;
}

.dragging-container tbody tr {
  background: #fff!important;
  xtransition: none!important;
}

.dragging-container tbody tr td {
  position: relative;
}

.dragging-container .drag-target-line {
  bottom: -1px;
  left: -500%;
  width: 1000%;
  xleft: 0;
  xwidth: 100%;
  height: 0;
  display: block;
  position: absolute;
  border-bottom: dashed 2px red;
  z-index: 2;
}

.dragging-container .drag-target-line.drag-target-top {
  bottom: auto;
  top: -1px;
}


.dragging-container .ant-table-tbody > tr.ant-table-row-hover > td,
.dragging-container .ant-table-tbody > tr > td
.dragging-container .ant-table-tbody > tr:hover > td {
  background: transparent;
}

.dialogPaper {
  minHeight: '80vh';
  maxHeight: '800vh';
}

